import React from 'react'

import { BlockNotFound } from '../../components/errors/BlockNotFound'

import { Entry } from 'contentful'

import { blocks } from '../../blocks'

export type BlockRendererProps = {
  block: Entry<any> | Entry<any>[]
  fieldPath?: string
}

const BlockRenderer = ({ block, fieldPath, ...rest }: BlockRendererProps & any) => {
  if (!block) {
    return null
  }

  if (Array.isArray(block)) {
    return (
      <>
        {block.map((b, index) => {
          return (
            <BlockRenderer
              key={`${index}-block-${b.sys.id}`}
              fieldPath={`${fieldPath ?? ''}.${index}`}
              block={b}
              {...rest}
            />
          )
        })}
      </>
    )
  }

  const contentTypeId = block.sys?.contentType?.sys?.id
  const Component: React.ComponentType = blocks[contentTypeId]

  if (!Component) {
    return <BlockNotFound blockName={contentTypeId} entryId={block?.sys?.id} entryTitle={block.fields?.entryTitle} />
  }

  const { id } = block.sys
  const componentProps = {
    ...block,
    ...(fieldPath ? { 'data-sb-field-path': fieldPath } : {}),
    ...rest,
  }

  return <Component key={`${contentTypeId}-${id}`} {...componentProps} />
}

export { BlockRenderer }
