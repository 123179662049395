import getConfig from 'next/config'

const config = getConfig()?.publicRuntimeConfig

import styled from 'styled-components'

const NotFound = styled.div({
  backgroundColor: 'firebrick',
  color: 'white',
  padding: '4px',
  strong: {
    fontWeight: 'bold',
  },
})

type BlockNotFoundProps = {
  entryId: string
  blockName?: string
  entryTitle?: string
}

export const BlockNotFound = ({ entryId, blockName, entryTitle }: BlockNotFoundProps) => {
  if (config.IS_PROD) {
    return undefined
  }

  const details = []
  details.push(`EntryID: ${entryId}`)
  if (blockName) {
    details.push(`Block Name: ${blockName}`)
  }
  if (entryTitle) {
    details.push(`Entry Title: ${entryTitle}`)
  }

  return <NotFound>Block Not Found - {details.join(' - ')}</NotFound>
}
